/*:root {*/
/*    --animate-duration: 800ms;*/
/*    --animate-delay: 0.9s;*/
/*}*/

.inline {
  display: inline-block;
}

.link {
}

body {
  /* background-color: #b8c6db;
    background-image: linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%); */
}

.logo {
  max-width: 122px;
  /*filter: grayscale(1);*/
}

p {
  /*text-indent: 30px;*/
}

.narrow {
  padding-left: 9%;
  padding-right: 9%;
}

img.intro {
  max-width: 100%;
  height: auto;
  padding-top: 10%;
}

img.headshot {
  padding: 0px;
  margin: 0px;
  height: 170px;
  width: 170px;
  border-radius: 50%;
  object-fit: cover;
  display: inline-block;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

img.headshotFooter {
  padding: 0px;
  margin: 0px;
  height: 112px;
  width: 112px;
  border-radius: 50%;
  object-fit: cover;
  display: inline-block;
  /*box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;*/
}

.emphasize {
  background-color: #d9e4f5;
  background-image: linear-gradient(315deg, #d9e4f5 0%, #f5e3e6 74%);
}

/* .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    align-items: center;
} */

.photo {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  max-width: 100%;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tag {
  margin: 8px;
  font-size: 14px;
  justify-content: center;
  align-content: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 22px;
}

.footer {
  font-family: "Lato";
}

.break {
  flex-basis: 100%;
  height: 0;
}

.projectCard {
  margin: 8px;
  font-size: 14px;
  justify-content: center;
  align-content: center;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 10px;
}

.videoCard {
  margin: 8px;
  font-size: 14px;
  justify-content: center;
  align-content: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 10px;
}

/* body {
    font-size: 16px;
} */

.sm-tag {
  margin: 4px;
  font-size: 12px;
  justify-content: center;
  align-content: center;
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
  border-color: #d9e4f5;
  border-width: 1px;
  border-style: solid;
  border-radius: 22px;
}

@media (max-width: 544px) {
  body {
    font-size: 15px;
  }

  .narrow {
    padding: 0px;
  }

  .tag {
    margin: 8px;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  /* .grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
        align-items: center;
    } */
}

.video {
  border-radius: 10px 10px 0 0;
}

.role {
  margin: 8px;
  font-size: 14px;
  height: 15%;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 12px;
}

.section {
  padding: 50px;
  border-style: solid;
  border-radius: 20px;
  border-width: 0px;
  border-color: lightgray;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 3px 28px; */
}

body {
  font-family: "Roboto Serif", sans-serif;
}
